import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from './common';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@hse-design/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "установка-библиотеки"
    }}>{`Установка библиотеки`}</h1>
    <p>{`Дизайн-система распространяется в виде трех библиотек:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://registry.design-system.hse.ru/-/web/detail/@hse-design/core"
        }}><inlineCode parentName="a">{`@hse-design/core`}</inlineCode></a>{` - css/scss-стили, иконки и ассеты`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://registry.design-system.hse.ru/-/web/detail/@hse-design/react"
        }}><inlineCode parentName="a">{`@hse-design/react`}</inlineCode></a>{` - React-компоненты`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://registry.design-system.hse.ru/-/web/detail/@hse-design/vue"
        }}><inlineCode parentName="a">{`@hse-design/vue`}</inlineCode></a>{` - Vue-компоненты`}</li>
    </ul>
    <p>{`Ниже описаны шаги для установки библиотеки компонентов React.
Если вы используете Vue, то соответствующая инструкция доступна по ссылке.`}</p>
    <Link subdomain="vue-design" href="/#/install" mdxType="Link">Установка библиотеки Vue</Link>
    <h2 {...{
      "id": "библиотека-для-react"
    }}>{`Библиотека для React`}</h2>
    <p>{`Для того, чтобы установить библиотеку, необходимо выполнить следующие шаги:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Пропишите адрес регистра пакетов для `}<inlineCode parentName="p">{`@hse-design`}</inlineCode>{` в файле `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{`.`}</p>
        <p parentName="li">{`В корне вашего репозитория создайте файл `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{` со следующим содержимым:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`@hse-design:registry=https://registry.design-system.hse.ru
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Установите библиотеку и ее peer-зависимости.`}</p>
        <p parentName="li">{`Выполните следующую команду в корне вашего проекта:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`yarn add @hse-design/react @hse-design/core focus-visible react react-dom react-modal react-toastify
`}</code></pre>
        <p parentName="li"><inlineCode parentName="p">{`react-modal`}</inlineCode>{` требуется для корректной работы компонента `}<a parentName="p" {...{
            "href": "/src-componets-Modal"
          }}>{`Modal`}</a>{`.`}</p>
        <p parentName="li">{`Вы можете не устанавливать `}<inlineCode parentName="p">{`react-toastify`}</inlineCode>{`, если не планируете использовать компонент `}<a parentName="p" {...{
            "href": "/src-componets-Toast"
          }}>{`Toast`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`В корневом файле вашего приложения (`}<inlineCode parentName="p">{`index.js`}</inlineCode>{`) пропишите необходимые для работы библиотеки импорты:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-typescript"
          }}>{`// Библиотека использует .focus-visible, для которого необходим полифилл
import 'focus-visible';

// Стили для ресета дефолтных
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
// Подключение шрифтов, которые использует библиотека
import '@hse-design/core/src/fonts.scss';
// [Опционально] Подключение глобальных стилей
// import '@hse-design/core/lib/global.css';
// Подключение стилей компонентов
import '@hse-design/core/lib/index.css';
// Подключение стилей, специфичных для React
import '@hse-design/core/lib/react/index.css';
`}</code></pre>
        <p parentName="li">{`По-умолчанию стили ДС влияют только на элементы с css-классами ДС.
Поэтому с поправкой на `}<inlineCode parentName="p">{`sanitize.css`}</inlineCode>{` вы можете подключить ДС в существующий проект без коллизий с существующими стилями.`}</p>
        <p parentName="li">{`Если вы начинаете новый проект, вы можете добавить также наши глобальные стили `}<inlineCode parentName="p">{`@hse-design/core/lib/global.css`}</inlineCode>{`,
которые задают шрифты по-умолчанию и стилизуют базовые элементы, такие как `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`p`}</inlineCode>{`, `}<inlineCode parentName="p">{`a`}</inlineCode>{` и т.п.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`В нужных местах прописать импорты компонентов и использовать их`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-tsx"
          }}>{`import { Button } from '@hse-design/react';

export const ButtonWrapper = () => (<Button>Button component</Button>);
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "canary-версия-библиотеки"
    }}>{`Canary версия библиотеки`}</h2>
    <p>{`У Дизайн-системы есть версии пакетов, помеченные тегом `}<inlineCode parentName="p">{`canary`}</inlineCode>{`. Эти версии публикуются автоматически в процессе разработки (при каждом обновлении ветки master).
В этих версиях доступны все последние нововведения, однако они могут быть нестабильны.`}</p>
    <p>{`Для установки canary версии используйте тег `}<inlineCode parentName="p">{`canary`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`yarn add @hse-design/react@canary @hse-design/core@canary
# or
npm i @hse-design/react@canary @hse-design/core@canary
`}</code></pre>
    <h2 {...{
      "id": "es-модули"
    }}>{`ES модули`}</h2>
    <p>{`Библиотека компонентов собирается как с ESM, так и CommonJS импортами.`}</p>
    <p>{`При использовании сборщика модулей в вашем приложении (например, `}<a parentName="p" {...{
        "href": "https://webpack.js.org/"
      }}>{`Webpack`}</a>{`, `}<a parentName="p" {...{
        "href": "https://rollupjs.org/"
      }}>{`Rollup`}</a>{`)
будет автоматически использоваться ESM версия. В финальный бандл приложения включатся только используемые
компоненты из библиотеки. Это происходит благодаря `}<a parentName="p" {...{
        "href": "https://webpack.js.org/guides/tree-shaking/"
      }}>{`tree-shaking`}</a>{`.`}</p>
    <p>{`Для корректной работы tree-shaking импортируйте отдельные компоненты из библиотеки, а не весь модуль. Например:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Button } from '@hse-design/react'
`}</code></pre>
    <h2 {...{
      "id": "поддерживаемые-браузеры"
    }}>{`Поддерживаемые браузеры`}</h2>
    <p>{`Мы гарантируем поддержку следующих браузеров:`}</p>
    <Table style={{
      tableLayout: 'fixed',
      maxWidth: '500px',
      width: '100%'
    }} mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      {['Браузер', 'Версия'].map(name => <TableHeadCell key={name} mdxType="TableHeadCell">{name}</TableHeadCell>)}
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    {[["Chrome", "≥63"], ["Safari", "≥11.1"], ["Edge", "≥18"], ["Firefox", "≥68"], ["Opera", "≥58"], ["Android", "≥5"], ["Android Chrome", "≥63"], ["Android Firefox", "≥68"], ["iOS Safari", "≥12.2"]].map(row => <TableRow mdxType="TableRow">
        {row.map(i => <TableCell mdxType="TableCell">
            {i}
          </TableCell>)}
      </TableRow>)}
  </TableBody>
    </Table>
    <p>{`Если вам нужна поддержка браузера, который не входит в список выше, `}<a parentName="p" {...{
        "href": "/contacts"
      }}>{`свяжитесь с нами`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      